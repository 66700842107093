<template>
  <div class="core-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CoreContainer',
};
</script>

<style scoped lang="scss">
.core-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
  min-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //height: fit-content;
  @media (min-width: 1052px) {
    max-width: 1052px;
    min-width: 1052px;
  }
}
</style>
